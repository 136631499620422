<template>
<v-card class="my-4">
  <v-toolbar v-if="toolbar" dense color="primary" dark>
    <v-toolbar-title>{{toolbar}} {{selectedDates}}</v-toolbar-title>
    <v-spacer></v-spacer>
  </v-toolbar>
  <v-card-text class="py-2">
  <v-row>
    <v-col cols="12">
      <v-radio-group
        class="mt-0"
        v-model="dateRange"
        row
        @change="setDate"
        hide-details
      >
        <v-radio small
          label="Current Quarter"
          value="thisQuarter"
        ></v-radio>
        <v-radio
          label="Previous Quarter"
          value="previousQuarter"
        ></v-radio>
        <v-radio
          label="Current Month"
          value="currentMonth"
        ></v-radio>
        <v-radio
          label="Previous Month"
          value="previousMonth"
        ></v-radio>
        <v-radio
          label="Custom Range"
          value="custom"
        ></v-radio>
        <v-radio
          label="All"
          value="all"
        ></v-radio>
      </v-radio-group>
    </v-col>
    <v-col cols="3">
      <v-dialog
        v-if="dateRange === 'custom'"
        ref="dialog"
        v-model="modal"
        persistent
        width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
          hide-details
          class="mt-0 pt-0"
            v-model="customRange"
            label="Select date range"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="customRange"
          range
          scrollable
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="modal = false"
          >
            Cancel
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="setDateRange"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-dialog>
    </v-col>
  </v-row>
  </v-card-text>
</v-card>
</template>

<script>
import moment from 'moment'
// import { mapGetters } from 'vuex'

export default {
  props: {
    toolbar: String
  },
  data () {
    return {
      dateRange: this.$store.getters['reports/dateRange'],
      currentMonth: [],
      previousMonth: [],
      previousQuarter: [],
      thisQuarter: [],
      all: ["2021-12-01", "2025-12-01"],
      customRange: this.$store.getters['reports/customRange'],
      modal: false,
    }
  },
  computed: {
    selectedDates () {
      const dates = this[this.dateRange]
      return `( ${dates[0]} - ${dates[1]} )`
    }
  },
  methods: {
    setDate () {
      switch (this.dateRange) {
        case 'currentMonth':
        case 'previousMonth':
        case 'previousQuarter':
        case 'thisQuarter':
        case 'all':
          this.$emit('getDates', this[this.dateRange])
          this.$store.dispatch('reports/setDateRange', this.dateRange)
          break
        default:
          this.$store.dispatch('reports/setDateRange', this.dateRange)
          break
      }
    },
    setDateRange () {
      this.$emit('getDates', this.customRange)
      this.$store.dispatch('reports/setDateRange', this.dateRange)
      this.$store.dispatch('reports/setCustomRange', this.customRange)
      this.modal = false
    }
  },
  async created () {
    const quarterOffset = {
      0: 1,
      1: 2,
      2: 0,
      3: 1,
      4: 2,
      5: 0,
      6: 1,
      7: 2,
      8: 0,
      9: 1,
      10: 2,
      11: 0
    }
    const lastMonthEnd = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD')
    const lastMonthStart = moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD')
    const thisQuarterStart = moment().subtract(quarterOffset[moment().month()], 'months').startOf('month').format('YYYY-MM-DD')
    const lastQuarterStart = moment(thisQuarterStart).subtract(3, 'months').startOf('month').format('YYYY-MM-DD')
    const lastQuarterEnd = moment(thisQuarterStart).subtract(1, 'months').endOf('month').format('YYYY-MM-DD')
    const thisMonthEnd = moment().endOf('month').format('YYYY-MM-DD')
    const thisMonthStart = moment().startOf('month').format('YYYY-MM-DD')
    this.currentMonth = [thisMonthStart, thisMonthEnd]
    this.previousMonth = [lastMonthStart, lastMonthEnd]
    this.previousQuarter = [lastQuarterStart, lastQuarterEnd]
    this.thisQuarter = [thisQuarterStart, thisMonthEnd]
    this.$emit('getDates', this.dateRange === 'custom' ? this.customRange : this[this.dateRange])
  }
}
</script>
<style lang="scss" scoped>
.v-input, .v-label {
  font-size: 11px !important
}
</style>
